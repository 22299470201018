import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { slice, concat, get } from 'lodash';
import PropTypes from 'prop-types';
import ObjectFitImages from 'object-fit-images';

import AnimateIn from '../components/animate-in';

// Components
import Layout from '../components/layout';
import ListContainer from '../components/list-container';
import Hero from '../components/hero';
import ActionPanel from '../components/action-panel';

// Utils
import ListingUtils from '../utils/listing-utils';
import ComponentMap from '../utils/component-map';
import SeoUtils from '../utils/seo-utils';

// Styles
import '../styles/templates/listing.scss';

// Component
class Listing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articleList: [],
      index: 0,
      loadMore: true,
      articlesLoaded: [],
      sortOption: { value: 'Most recent', label: 'Most Recent' },
    };
  }

  async componentDidMount() {
    this.getArticleList();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  /**
   * Handle loading of articles
   *
   * @param {number} [limit=6] Max limit of articles on eaach load
   */
  loadArticles = (limit = 6) => {
    const { articleList, index, articlesLoaded } = this.state;
    const totalLength = articleList.length;
    const newIndex = index + limit;
    const newLoadMore = newIndex < totalLength - 1;

    // Splice the array based on index to conncat new set of list
    const newArticlesLoaded = concat(
      articlesLoaded,
      slice(articleList, index, newIndex)
    );

    this.setState(
      {
        articlesLoaded: newArticlesLoaded,
        loadMore: newLoadMore,
        index: newIndex,
      },
      () => {
        const objectFitImageList = document.querySelectorAll(
          'img.object-fit-image'
        );
        ObjectFitImages(objectFitImageList);
      }
    );
  };

  // Get articles on load
  getArticleList = () => {
    const articleModels = this.props.data;
    // Merge all the aricles inside each tag
    const articleList = ListingUtils.mergeArticleList(articleModels);

    this.setState({ articleList }, async () => {
      await this.loadArticles();
      await this.handleSorting(this.state.sortOption);
    });
  };

  // handle article sort
  handleSorting = (sortOption) => {
    const { articleList, index } = this.state;

    let sortedArr = articleList;

    // Check if sorted by recent
    if (sortOption.value === 'Most recent') {
      sortedArr = ListingUtils.sortRecentDate(articleList);
    } else if (sortOption.value === 'Most popular') {
      sortedArr = ListingUtils.sortPopularity(articleList);
    }

    const sortListByDateTimeDescending = (dateString) => Date.parse(dateString);
    sortedArr.sort((a, b) => {
      const dateA = sortListByDateTimeDescending(a.meta.createdAt);
      const dateB = sortListByDateTimeDescending(b.meta.createdAt);
      return dateB - dateA;
    });
    // Splice the array based on index to conncat new set of list
    const loadedArticles = slice(sortedArr, 0, index);

    this.setState({
      sortOption,
      articlesLoaded: loadedArticles,
      articleList: sortedArr,
    });
  };

  // render
  render() {
    const { loadMore, articlesLoaded, articleList } = this.state;
    const { articleTagData } = this.props.pageContext;

    const queryData = get(articleTagData, 'content');

    // Component props
    const ComponentProps = {
      title: get(articleTagData, 'articleTagTitle'),
      slug: get(articleTagData, 'slug'),
      description: get(articleTagData, 'articleTagSubtitle'),
      count: articleList.length,
      backgroundColor: get(articleTagData, 'backgroundColor.hex'),
      signPostContent: get(articleTagData, 'content'),
    };

    // Seo
    const seoTags = get(articleTagData, 'seoMetaTags.tags');
    const seoProps = {
      description:
        SeoUtils.extractProperty(seoTags, 'name', 'description') ||
        ComponentProps.description,
      twitterCardType: SeoUtils.extractProperty(
        seoTags,
        'name',
        'twitter:card'
      ),
      cardImage: SeoUtils.extractProperty(seoTags, 'property', 'og:image'),
    };

    // Render components based on module
    const RenderComponents = queryData
      ? queryData.map((eacModule) => {
          const label = get(eacModule, 'model.apiKey');
          const ComponentProp = {
            data: eacModule,
          };
          const eachComponent = ComponentMap(
            label,
            ComponentProp,
            eacModule.id
          );
          return eachComponent;
        })
      : [];

    // render
    return (
      <Layout title={ComponentProps.title} seoProps={seoProps}>
        <Hero
          title={ComponentProps.title}
          description={ComponentProps.description}
          location={[
            { id: '1', value: 'Homepage', location: '/' },
            {
              id: '2',
              value: 'Resource center',
              location: '/resource-center',
            },
            {
              id: '3',
              value: ComponentProps.title,
              location: `/${ComponentProps.slug}`,
            },
          ]}
          backgroundColor={ComponentProps.backgroundColor}
          longHeader="true"
        />
        <section className="article-list">
          <div className="wrapper">
            <div className="article-filter">
              <span className="article-count">{`${ComponentProps.count} ${ComponentProps.title}`}</span>
            </div>
            <ListContainer
              loadMore={loadMore}
              list={articlesLoaded}
              handleLoadMore={this.loadArticles}
            />
          </div>
        </section>
        <ActionPanel />
        {RenderComponents}
      </Layout>
    );
  }
}

Listing.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  pageContext: PropTypes.objectOf(PropTypes.object).isRequired,
};

/**
 * Query
 * Note: This is reqired due to datoCMS's
 *       inability to add multiple modular content in a single model.
 */
export const query = graphql`
  query TaggedAricles($slug: String!) {
    allDatoCmsWebinar(
      filter: { selectArticleTagCategory: { slug: { eq: $slug } } }
    ) {
      nodes {
        id
        title
        subtitle
        slug
        articleCategory
        views
        originalId
        featuredImage {
          url
        }
        meta {
          createdAt
          publishedAt(formatString: "DD MMMM, YYYY")
          updatedAt
        }
        selectArticleTagCategory {
          articleTagTitle
          slug
        }
      }
    }
    allDatoCmsAgencyGuide(
      filter: { selectArticleTagCategory: { slug: { eq: $slug } } }
    ) {
      nodes {
        id
        title
        subtitle
        slug
        articleCategory
        views
        originalId
        featuredImage {
          url
        }
        meta {
          createdAt
          publishedAt(formatString: "DD MMMM, YYYY")
          updatedAt
        }
        selectArticleTagCategory {
          articleTagTitle
          slug
        }
      }
    }
    allDatoCmsAgencySuccessStory(
      filter: { selectArticleTagCategory: { slug: { eq: $slug } } }
    ) {
      nodes {
        id
        previewTitle
        title
        subtitle
        slug
        articleCategory
        views
        originalId
        featuredImage {
          url
        }
        meta {
          createdAt
          publishedAt(formatString: "DD MMMM, YYYY")
          updatedAt
        }
        selectArticleTagCategory {
          articleTagTitle
          slug
        }
      }
    }
    allDatoCmsOnDemandTraining(
      filter: { selectArticleTagCategory: { slug: { eq: $slug } } }
    ) {
      nodes {
        id
        title
        subtitle
        slug
        articleCategory
        views
        originalId
        featuredImage {
          url
        }
        meta {
          createdAt
          publishedAt(formatString: "DD MMMM, YYYY")
          updatedAt
        }
        selectArticleTagCategory {
          articleTagTitle
          slug
        }
      }
    }
    allDatoCmsCarrierBrochure(
      filter: { selectArticleTagCategory: { slug: { eq: $slug } } }
    ) {
      nodes {
        id
        title
        articleCategory
        views
        originalId
        uploadFile {
          url
          size
          format
        }
        meta {
          createdAt
          publishedAt(formatString: "DD MMMM, YYYY")
          updatedAt
        }
        selectArticleTagCategory {
          articleTagTitle
        }
      }
    }
    allDatoCmsPressRelease(
      filter: { selectArticleTagCategory: { slug: { eq: $slug } } }
    ) {
      nodes {
        id
        title
        subtitle
        slug
        articleCategory
        views
        originalId
        featuredImage {
          url
        }
        meta {
          createdAt
          publishedAt(formatString: "DD MMMM, YYYY")
          updatedAt
        }
        selectArticleTagCategory {
          articleTagTitle
          slug
        }
      }
    }
    allDatoCmsBlog(
      filter: { selectArticleTagCategory: { slug: { eq: $slug } } }
    ) {
      nodes {
        id
        title
        subtitle
        slug
        articleCategory
        views
        originalId
        featuredImage {
          url
        }
        featuredImageMobile {
          url
        }
        meta {
          createdAt
          publishedAt(formatString: "DD MMMM, YYYY")
          updatedAt
        }
        selectArticleTagCategory {
          articleTagTitle
          slug
        }
      }
    }
    allDatoCmsArticle(
      filter: { selectArticleTagCategory: { slug: { eq: $slug } } }
    ) {
      nodes {
        id
        title
        subtitle
        slug
        articleCategory
        views
        originalId
        featuredImage {
          url
        }
        meta {
          createdAt
          publishedAt(formatString: "DD MMMM, YYYY")
          updatedAt
        }
        selectArticleTagCategory {
          articleTagTitle
          slug
        }
      }
    }
  }
`;

export default AnimateIn(Listing);
